import React, { Component } from 'react';
import Layout from "../components/layout";
import Intro from "../components/intro";
import BlogItem from "../components/blogItem";
import BlogCategory from "../components/blog-category";
import Nav from '../components/nav';
import moment from 'moment';

class IndexPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            isBlogLoaded: false,
            blogContent: [],
            isAwardLoaded: false,
            awardContent: [],
            isCategoryLoaded: false,
            categoryContent: [],
            load_more: 10,
            tag_name: null
        }
    }

    async componentDidMount() {
        const id = this.props
        let tag_name = id.params["*"];
        this.getAllBlog(tag_name);
        this.getAllCategoryByType();
        this.setState({tag_name:tag_name})
    }

    componentWillReceiveProps(nextProps) {
        let tag_name = nextProps.params["*"];
        this.getAllBlog(tag_name);
        this.getAllCategoryByType();
        this.setState({tag_name:tag_name})
    }

    load_more = () => {
        this.setState({ load_more: this.state.load_more + 10 })
    }

    getAllBlog(tag_name) {
        fetch("https://tatva.com/backendapi/api/v1/cms/by-type/blog")
            .then(res => res.json())
            .then(
                (result) => {



                    const filteredDates = result.data.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
                    console.log("filteredDates", filteredDates)

                    // this.setState({
                    //     isBlogLoaded: true,
                    //     blogContent: filteredDates
                    // });

                    let result2 = result.data.reduce(function (r, a) {
                        r[a.category] = r[a.category] || [];
                        r[a.category].push(a);
                        return r;
                    }, Object.create(null));
                    // console.log("result2", result2);


                    let result2publish_date = result.data.reduce(function (r, a) {
                        // let year = moment(a.publish_date,"YYYY-MM-DD").year()
                        // console.log("year", year + a.publish_date)
                        r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [];
                        // console.log(r[moment(a.publish_date, "YYYY-MM-DD").year()] = r[moment(a.publish_date, "YYYY-MM-DD").year()] || [])
                        r[moment(a.publish_date, "YYYY-MM-DD").year()].push(a);
                        // console.log(a)
                        return r;
                    }, Object.create(null));
                    // console.log("result2publish_date", result2publish_date);



                    this.setState({
                        isBlogLoaded: true,
                        blogContent: filteredDates,
                        category_count: result2,
                        year_count: result2publish_date
                    });

                    this.setState({
                        isBlogLoaded: true,
                        dataList: result.data,
                        items: result.data,
                    });
                    this.filterData(tag_name)

                },
                (error) => {
                    this.setState({
                        isBlogLoaded: true,
                        error
                    });
                }
            )
    }

    // filter records by search text
    excludeColumns = ["_id", "slug", "description", "short_description", "type", "meta_tag", "meta_description", "document_title", "publish_type", "status", "created_by", "publish_date", "thumbnail", "image", "resource_file", "additional_fields", "__v"];

    filterData = (value) => {
        console.log("filterData", value)
        const lowercasedValue = value?.toLowerCase().trim();
        if (lowercasedValue === "") {
            // setData(dataList);
            this.setState({ items: this.state.dataList })
        }
        else {
            console.log("this state data filterData", this.state.items)
            const filteredData = this.state.items.filter(item => {
                return Object.keys(item)?.some(key =>
                    this.excludeColumns.includes(key) ? false : item[key]?.toString().toLowerCase().includes(lowercasedValue)
                );
            });
            // setData(filteredData);
            console.log("filteredData", filteredData)
            this.setState({ blogContent: filteredData, search_text: value, search_result_length: filteredData.length })
        }
    }

    getAllCategoryByType() {
        fetch("https://tatva.com/backendapi/api/v1/cms-category/by-cms-type/blog")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isCategoryLoaded: true,
                        categoryContent: result
                    });
                },
                (error) => {
                    this.setState({
                        isCategoryLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const {  blogContent, isCategoryLoaded, categoryContent, load_more, tag_name,
        
            category_count,
            year_count,
            isBlogLoaded
        
        } = this.state;
        return (
            <Layout>

                {isBlogLoaded ?
                    <Nav
                        active="blog"
                        pageTitle={'Blog By ' + tag_name}
                        title={''}
                        description={''}
                        keywords={''}
                    ></Nav>
                    : ''}

                <Intro title="Our Blog" search="true"
                        searchType="blog" description="Our blog provides legal and commercial information, analysis and updates of national and regional relevance."> </Intro>

                <div className="main-container" id="blog-category-page">
                    <div className="container">
                        {isBlogLoaded ?
                            <div className="blog-category-top">
                                <span className="blog-category-top-link" title={tag_name}>
                                    <span dangerouslySetInnerHTML={{ __html: tag_name }}></span> </span>
                            </div>
                            : ''}
                        <div className="row">
                            <div className="col-md-9 col-sm-12">
                                <div className="blog-container">
                                    {isBlogLoaded ?
                                        <React.Fragment>
                                            {blogContent.slice(0, load_more).map(item => (
                                                <div className="" key={item._id}>
                                                    <BlogItem
                                                        category_show="true"
                                                        category={item.category}
                                                        title={item.title}
                                                        author={item.created_by}
                                                        date={item.publish_date}
                                                        short_content={item.short_description}
                                                        slug={item.slug}
                                                        description={item.description}
                                                    >
                                                    </BlogItem>
                                                </div>
                                            ))}
                                            
                                        </React.Fragment>
                                        : ''}
                                </div>
                            </div>
                            {isCategoryLoaded & isBlogLoaded ?
                                <BlogCategory
                                    category_count={category_count}
                                    year_count={year_count}
                                    className={"col-md-3 col-sm-12"} categoryContent={categoryContent}></BlogCategory>
                                : ''}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default IndexPage;